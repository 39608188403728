import { Dsr, DsrDetail } from "../types";
import { ReviewActionTypes } from "../actions/review";
import { SET_LOADING, LIST_DSR, SEARCH_DSR, LIST_DSR_COMPLETE, SEARCH_DSR_COMPLETE, DSR_DETAIL, LIST_DSR_FAIL, DSR_DETAIL_FAIL } from "../actions/types";

export interface ReviewState {
  loading: boolean,
  loadMore: boolean,
  dsrs: Dsr[],
  dsrDetail: DsrDetail
}

const initialState: ReviewState = {
  loading: true,
  loadMore: true,
  dsrs: [],
  dsrDetail: {} as DsrDetail,
}

export default function (state = initialState, action: ReviewActionTypes) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: true };
    case LIST_DSR:
    case SEARCH_DSR:
      if (action.payload.offset === 0) {
        return { ...state, dsrs: action.payload.dsrs, loadMore: true, loading: false };
      }
      return { ...state, dsrs: [...state.dsrs, ...action.payload.dsrs], loading: false };
    case LIST_DSR_COMPLETE:
    case SEARCH_DSR_COMPLETE:
      return { ...state, loadMore: false, loading: false };
    case DSR_DETAIL:
      return { ...state, dsrDetail: action.dsrDetail, loading: false };
    case LIST_DSR_FAIL:
      return { ...state, dsrs: [], loading: false };
    case DSR_DETAIL_FAIL:
      return { ...state, dsrDetail: {} as DsrDetail, loading: false };
    default:
      return state;
  }
}