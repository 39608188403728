import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonLabel, IonItem } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CampaignContainer from '../components/CampaignContainer';
import { getBrandAction, addCampaignAction, getMarkets, getGenres } from '../actions/campaign';
import { setAlert } from '../actions/alert';
import { resetTGAction } from '../actions/dsr';
import { RootState } from '../reducers';
import { Brand, Market, CampaignGenre, Campaign } from '../types';

interface ParamTypes {
  brand_id: string
}

const CampaignPage: React.FC = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const brands = useSelector<RootState, Brand[]>(state => state.brand.brands);
  const campaign = useSelector<RootState, Campaign>(state => state.campaign.campaign);
  const markets = useSelector<RootState, Market[]>(state => state.campaign.markets);
  const genres = useSelector<RootState, CampaignGenre[]>(state => state.campaign.genres);
  const tgUpdate = useSelector<RootState, boolean>(state => state.dsr.tgUpdate);

  const { brand_id } = useParams<ParamTypes>();

  useEffect(() => {
    if (brand_id) {
      const brand = brands.find(brand => brand.brand_id === +brand_id);
      if (brand) {
        dispatch(getBrandAction(brand));
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (markets.length < 1) {
      dispatch(getMarkets());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (genres.length < 1) {
      dispatch(getGenres());
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {

    if (!campaign.marketDefinition || !campaign.genres || campaign.genres.length < 1) {
      dispatch(setAlert('All fields are mandatory', 'danger'));
      return;
    }
    if ((!campaign.keyMarkets || campaign.keyMarkets.length < 1) && !campaign.otherMarket) {
      dispatch(setAlert('Choose key market or provide other market name ', 'danger'));
      return;
    }
    const concerns = campaign.genres.find(genre => !genre.concern);
    if (concerns) {
      dispatch(setAlert('Please specify concerns for each selected genre', 'danger'));
      return;
    }
    dispatch(addCampaignAction(campaign.brand_id));

    if (tgUpdate) {
      dispatch(resetTGAction(false));
      // An additional back to go past the edit brand screen
      history.goBack();
    }
    history.goBack();
  }

  return (
    <IonPage>
      <IonHeader translucent={true} collapse='fade'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>Campaign</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Campaign</IonTitle>
          </IonToolbar>
        </IonHeader>
        {campaign && campaign.brand_id && markets.length > 0 && genres.length > 0 ? (<CampaignContainer />) : (
          <IonItem lines='none' className='ion-text-center'>
            <IonLabel>Detail for brand with id {brand_id} not found</IonLabel>
          </IonItem>
        )}
      </IonContent>
      <IonFooter>
        <IonButton disabled={!campaign} expand='block' onClick={() =>
          onSubmit()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default CampaignPage;
