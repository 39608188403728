import React, { useState } from 'react'
import { IonRadioGroup, IonItem, IonLabel, IonRadio, IonTextarea, IonList, IonCheckbox, IonDatetime, IonModal, IonButton, IonIcon, IonInput } from '@ionic/react';
import { MARKET_DEFINITIONS, OTHERS } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMediaBriefAction, setKeyMarketsAction, setMarketDefinitionAction, setCampaignDateAction, setKeyOtherMarketAction,
  setGenresAction, selectOtherMarketAction
} from '../actions/campaign';
import ConcernComponent from './ConcernComponent';
import { bugOutline } from 'ionicons/icons';
import { RootState } from '../reducers';
import { Campaign, Genre, Market } from '../types';
import { addDays, addYears, format } from 'date-fns';


const CampaignContainer: React.FC = () => {

  const minCampaignDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');
  const maxCampaignDate = format(addYears(new Date(), 1),'yyyy-MM-dd');
  const [showCampaignDate, setShowCampaignDate] = useState(false)

  const [showModal, setShowModal] = useState(false);

  const [genre, setGenre] = useState<Genre>({} as Genre);

  const dispatch = useDispatch();

  const campaign = useSelector<RootState, Campaign>(state => state.campaign.campaign);
  const genres = useSelector<RootState, Genre[]>(state => state.campaign.genres);
  const markets = useSelector<RootState, Market[]>(state => state.campaign.markets);

  const onCampaignDateSelected = (value:string) => {
    setShowCampaignDate(false);
    dispatch(setCampaignDateAction(value));
  }

  const onOtherMarketChange = (checked: boolean) => {
    dispatch(selectOtherMarketAction(checked));
    if (!checked) {
      dispatch(setKeyOtherMarketAction(OTHERS.value));
    }
  }

  async function closeModal() {
    await setShowModal(false);
  }

  const onConcernsClicked = (genre: Genre) => {
    const brandGenre = campaign.genres && campaign.genres.find(item => item.id === genre.id);
    if (brandGenre) {
      setGenre(brandGenre);
    } else {
      setGenre(genre);
    }
    setShowModal(true);
  }

  return (
    <>
      <IonItem>
        <IonTextarea name='mediaBrief' value={campaign.mediaBrief}
          placeholder='Media Brief/Objectives'
          onIonChange={e => setMediaBriefAction(e.detail.value!)}></IonTextarea>
      </IonItem>

      <IonItem lines='none'>
        <IonLabel>Key Markets</IonLabel>
      </IonItem>

      <IonList>
        {markets.map(market => (
          <IonItem lines='none' key={market.id}>
            <IonLabel>{market.name}</IonLabel>
            <IonCheckbox checked={market.checked} slot="start" onIonChange={e => dispatch(setKeyMarketsAction(market.id, e.detail.checked))} />
          </IonItem>
        ))}
      </IonList>

      <IonItem lines='none'>
        <IonLabel>{OTHERS.name}</IonLabel>
        <IonCheckbox slot='start' checked={campaign.otherMarketChecked} onIonChange={e => onOtherMarketChange(e.detail.checked)} />
      </IonItem>
      {campaign.otherMarketChecked &&
        <IonItem>
          <IonInput placeholder='Other Market' type="text" name="otherMarket" value={campaign.otherMarket}
            onIonChange={e => dispatch(setKeyOtherMarketAction(e.detail.value!))}></IonInput>
        </IonItem>
      }

      <IonItem lines='none'>
        <IonLabel>Key Market Definition</IonLabel>
      </IonItem>
      <IonRadioGroup value={campaign.marketDefinition} onIonChange={(e) => dispatch(setMarketDefinitionAction(e.detail.value))}>
        {MARKET_DEFINITIONS.map(def =>
          <IonItem lines='none' key={def}>
            <IonLabel>{def}</IonLabel>
            <IonRadio mode='md' slot="start" value={def} />
          </IonItem>)
        }
      </IonRadioGroup>

      <IonItem lines='none'>
        <IonLabel className='ion-text-wrap' >Next Campaign Planned For</IonLabel>
        <IonInput readonly name="anniversary" value={campaign.campaignDate ? format(new Date(campaign.campaignDate), 'dd-MM-yyyy') : 'Select'}
            onClick={e => setShowCampaignDate(true)}></IonInput>
      </IonItem>
      {showCampaignDate && <IonDatetime value={campaign.campaignDate} name='campaignDate' min={minCampaignDate} max={maxCampaignDate} onIonChange={e => onCampaignDateSelected(e.detail.value!)} ></IonDatetime>}

      <IonItem lines='none'>
        <IonLabel>Genre Under Consideration</IonLabel>
      </IonItem>

      <IonModal isOpen={showModal} className="fullscreen" onDidDismiss={() => setShowModal(false)}>
        <ConcernComponent genre={genre} onClose={closeModal}></ConcernComponent>
      </IonModal>

      <IonList>
        {genres.map(genre => (
          <IonItem lines='none' key={genre.id}>
            <IonLabel>{genre.name}</IonLabel>
            <IonCheckbox checked={genre.checked} slot="start" onIonChange={e => dispatch(setGenresAction(genre.id, e.detail.checked))} />
            <IonButton fill='clear' slot='end' disabled={!genre.checked} onClick={() => onConcernsClicked(genre)}><IonIcon slot='icon-only' icon={bugOutline}></IonIcon></IonButton>
          </IonItem>
        ))}
      </IonList>
      <IonItem lines='none'>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </>
  )
}

export default CampaignContainer;